<template>
  <a-row>
    <a-col :span="18" :offset="3">
      <a-layout class="hohux-tools">

        <a-layout-content>

          <div class="tools-title">
            <span>
                <a-divider type="vertical" class="tools-title-span"/>
              开发者工具下载
            </span>
          </div>

          <a-list item-layout="horizontal" :data-source="data">
            <template #renderItem="{ item }">
              <a-list-item>
                <template #actions>
                  <a :href="item.help" v-if="item.help" target="_blank" key="list-loadmore-help">使用帮助</a>
                  <a :href="item.download" target="_blank" key="list-loadmore-edit">下载</a>
                </template>
                <a-list-item-meta :description="item.description">
                  <template #title>
                    <a href="https://www.antdv.com/">{{ item.title }}</a>
                  </template>
                </a-list-item-meta>
              </a-list-item>
            </template>
          </a-list>
        </a-layout-content>

      </a-layout>
    </a-col>
  </a-row>
</template>

<script>

const data = [
  {
    title: 'HoHux插件开发测试工具',
    description: 'HoHux插件开发测试工具是用于测试开发完成的HoHux插件是否可以正常运行。更多使用信息，请查看产品使用文档-插件开发指南-开发与测试。',
    help: 'https://docs.hohux.com/',
    download: 'https://file.hohux.com/file/HoHux%E6%8F%92%E4%BB%B6%E5%BC%80%E5%8F%91%E6%B5%8B%E8%AF%95%E5%B7%A5%E5%85%B7.zip',
  }, {
    title: 'TCP&UDP测试工具',
    description: 'TCP&UDP测试工具是一款用于软件开发的测试工具，该工具可发送和接收TCP，UDP数据，可以检测网络通讯程序能否正常的运行，' +
        'TCP&UDP测试工具主要用于在网络通讯程序开发时，测试TCP或UDP通讯连接和测试数据的接收和发送情况。',
    help: '',
    download: 'https://file.hohux.com/file/TCP&UDP%E6%B5%8B%E8%AF%95%E5%B7%A5%E5%85%B7.7z',
  }, {
    title: 'MQTT测试工具',
    description: "MQTT.fx是一款基于Eclipse Paho，使用Java语言编写的MQTT客户端，支持Windows、Mac和Linux操作系统，可用于验证设备是否" +
        "可与物联网平台正常连接，并通过Topic订阅和发布消息。",
    help: 'https://mqttfx.jensd.de',
    download: 'https://www.jensd.de/apps/mqttfx/1.7.1/?spm=a2c4g.11186623.0.0.3d1b1e67ymq8vg',
  }
];

export default {
  name: "Tools",
  setup() {
    return {
      data,
    };
  },
}
</script>

<style>

.hohux-tools {
  margin-top: 20px;
  background-color: #ffffff;
  padding: 20px;
}

.tools-title {
  font-size: 20px;
  font-weight: 600;
  height: 60px;
  line-height: 60px;
  margin-bottom: 16px;
  margin-top: 20px;
}

.tools-title-span {
  height: 60px;
  background-color: #7cb305;
}
</style>